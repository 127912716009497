import $ from 'jquery';
import { ErrorCode } from '../consts/ErrorCode';
import { Common } from '../consts/Common';
import { store } from '../store/Store';
import { GlobalStateAction } from '../actions/GlobalStateAction';
import { judgeIsFromTMC, UrlTool } from './index';
import { v4 as uuidV4 } from 'uuid';

const doRPC = (method = 'GET', url, data, dataType = 'json') => {
  if (url.indexOf('?') === -1) {
    url += '?';
  }
  if (method === 'GET' && data) {
    Object.keys(data).forEach((key) => {
      if (data[key] != null) {
        url += `&${key}=${data[key]}`;
      }
    });
  }

  const csrfToken = window.pageProps.token;
  const v2CsrfToken = window.pageProps.v2csrf;
  const defer = $.Deferred();
  const v1CsrfTokenString = csrfToken ? `&csrfToken=${csrfToken}` : '';
  const v2CsrfTokenString = v2CsrfToken ? `&_csrf=${v2CsrfToken}` : '';
  const urlWithToken = url.indexOf('/api/v1/init/back') > -1 ? url : `${url}${v1CsrfTokenString}${v2CsrfTokenString}`;

  const $xhr = $.ajax({
    type: method,
    url: urlWithToken,
    contentType: 'application/json;charset=utf-8',
    timeout: 180000,
    dataType: 'json',
    data: method === 'GET' ? '' : JSON.stringify(data),
    headers: {
      ['Fe-Trace-Id']: uuidV4(), // 生成前端请求traceId
    },
  })
    .done((resp) => {
      if (dataType === 'html') {
        defer.resolve(resp);
      }
      if (resp.code === 0) {
        defer.resolve(resp.data);
      } else {
        if (resp.code === -2) {
          // 免登录过期情况下访问接口，则用postMessage告知TMC侧 重新获取新token
          if (judgeIsFromTMC()) {
            top.postMessage({ type: 'freshToken' }, '*');
          }

          if (window.location.pathname.indexOf('advertiser') === -1) {
            // 企微侧边栏
            window.location = Common.sidebar_auth_url;
          } else if (window.pageProps.from === 'qw') {
            // 企微平台
            window.location = Common.qw_auth_url;
          }
        } else if (
          ErrorCode.NO_LOGIN === resp.code
          || ErrorCode.ACCOUNT_NOT_EXIST === resp.code
          || ErrorCode.AGENCY_REJECT === resp.code
          || ErrorCode.NO_ACCOUNT_RIGHT === resp.code
          || ErrorCode.WECHAT_NOT_AUTHORIZE === resp.code
        ) {
          store.dispatch({
            type: GlobalStateAction.SHOW_TIPS,
            message: resp.msg,
            callBack: () => {
              UrlTool.gotoIndex();
            },
          });
        } else if (ErrorCode.SECOND_NOT_LOGIN === resp.code && UrlTool.checkIfNeedSecondLogin()) {
          UrlTool.gotoSecondLogin();
        } else {
          defer.reject(resp.code, resp.msg, resp.xh, resp.data);
        }
      }
    })
    .fail((xh, status, msg) => {
      defer.reject(status, msg, xh);
    });
  const $promise = defer.promise();
  $promise.abort = $xhr.abort;
  return $promise;
};

export const RPCFactory = {
  init: {
    init: () => doRPC('GET', `/api/v1/init/init?accountId=${window.pageProps.uid}`, {
      ...(window.pageProps.corpId ? { corpId: window.pageProps.corpId } : {}),
    }),
    getCSRF: () => doRPC('GET', '/back/', {}, 'html'),
    /** 获取v2版本接口的csrf token */
    getV2CSRF: data => doRPC('GET', '/api/v2/common/csrf_token', data),
    getToken: () => doRPC('GET', `/api/v1/init/back?accountId=${window.pageProps.uid}`, {}),
  },

  call: {
    call: (uid, callee, leadsId) => doRPC('POST', `/api/v1/call/pstn_call?accountId=${uid}&leadsId=${leadsId}`, { callee }),
    checkCallAccountValid: (uid, leadsId) => doRPC('GET', `/api/v1/call/check_callout_privilege?accountId=${uid}&leadsId=${leadsId}`, {}),
    getConfig: () => doRPC(
      'GET',
      `/api/v1/call_out_config/get?accountId=${window.pageProps.uid}`,
      {},
    ),
    updateConfig: (params = {}) => doRPC(
      'POST',
      `/api/v1/call_out_config/set?accountId=${window.pageProps.uid}`,
      params,
    ),
    getCities: () => doRPC(
      'GET',
      `/api/v1/call_out_config/get_city_list?accountId=${window.pageProps.uid}`,
      {},
    ),
    getSpeechList: () => doRPC(
      'POST',
      `/api/v1/call/speech/list?accountId=${window.pageProps.uid}`,
      {},
    ),
    addSpeech: (params = {}) => doRPC(
      'POST',
      `/api/v1/call/speech/add?accountId=${window.pageProps.uid}`,
      params,
    ),
    updateSpeech: (params = {}) => doRPC(
      'POST',
      `/api/v1/call/speech/update?accountId=${window.pageProps.uid}`,
      params,
    ),
    deleteSpeech: (params = {}) => doRPC(
      'POST',
      `/api/v1/call/speech/delete?accountId=${window.pageProps.uid}`,
      params,
    ),
  },

  customer: {
    getAdUserByUid: uid => doRPC('GET', `/api/v1/customer/aduser?accountId=${uid}`, {}),
  },

  operationUser: {
    getLawStatusById: accountId => doRPC('GET', `/api/v1/employee/agreement_consent_status/get?accountId=${accountId}`, {}),
    updateLawStatus: accountId => doRPC('POST', `/api/v1/employee/agreement_consent_status/set?accountId=${accountId}`, {}),
    getLoginUserInfo: uid => doRPC('GET', `${'/api/v1/operation_user_info' + '?accountId='}${uid}`, {}),
    updateLoginUserInfo: (uid, name, tel, qq, wxId) => doRPC('POST', `${'/api/v1/operation_user_info' + '?accountId='}${uid}`, {
      name,
      tel,
      qq,
      wxId,
    }),
    checkQQBind: (uid, qq) => doRPC('POST', `${'/api/v1/operation_user_bind/checkqq' + '?accountId='}${uid}`, { qq }),
    checkWxIdBind: (uid, wxId) => doRPC('POST', `${'/api/v1/operation_user_bind/checkwxid' + '?accountId='}${uid}`, { wxId }),
  },
  leadsApiData: {
    getReport_leads: (accountId, startActionDate, endActionDate) => doRPC(
      'GET',
      `${'/api/v1/statistics/report_leads' + '?accountId='
      }${accountId}&startActionDate=${startActionDate}&endActionDate=${endActionDate}`,
      {},
    ),
    getReport_list: (accountId, startActionDate, endActionDate, pageIndex, pageSize) => doRPC(
      'GET',
      `${'/api/v1/userapp/report_list' + '?accountId='
      }${accountId}&startActionDate=${startActionDate}&endActionDate=${endActionDate}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      {},
    ),
  },
  leadsApiManage: {
    testPushUrl: (accountId, leadsPushUrl) => doRPC('GET', `${'/api/v1/userapp/test_push_url' + '?accountId='}${accountId}&leadsPushUrl=${leadsPushUrl}`, {}),
    getAPiSetting: accountId => doRPC('GET', `${' /api/v1/userapp/get' + '?accountId='}${accountId}`, {}),
    sendToken: (accountId, mobile, smsCode) => doRPC('POST', `${' /api/v1/userapp/send_api_token' + '?accountId='}${accountId}`, {
      mobile,
      smsCode,
    }),
    savePushUrl(accountId, leadsPushUrl, mobile, smsCode) {
      const params = {};
      if (mobile) {
        params.mobile = mobile;
      }
      if (smsCode) {
        params.smsCode = smsCode;
      }
      return doRPC(
        'POST',
        `${' /api/v1/userapp/save_push_url' + '?accountId='}${accountId}&leadsPushUrl=${leadsPushUrl}`,
        params,
      );
    },
    getRepeatLeads(accountId) {
      return doRPC('GET', `${' /api/v1/userapp/get_hide_repeat_leads' + '?accountId='}${accountId}`, {});
    },
    getPushDelay(accountId) {
      return doRPC('GET', `${'/api/v1/userapp/get_push_delay' + '?accountId='}${accountId}`, {});
    },
    setPushDelay(accountId, pushDelay) {
      return doRPC('POST', `${'/api/v1/userapp/set_push_delay' + '?accountId='}${accountId}`, {
        pushDelay,
      });
    },
    setRepeatLeads(accountId, hideRepeatRegister) {
      return doRPC('POST', `${' /api/v1/userapp/set_hide_repeat_leads' + '?accountId='}${accountId}`, {
        hideRepeatRegister,
      });
    },
    getFilterImportLeads(accountId) {
      return doRPC('GET', `${' /api/v1/userapp/get_hide_import_leads' + '?accountId='}${accountId}`, {});
    },
    setFilterImportLeads(accountId, hideImportLeads) {
      return doRPC('POST', `${' /api/v1/userapp/set_hide_import_leads' + '?accountId='}${accountId}`, {
        hideImportLeads,
      });
    },
  },
  leadsManage: {
    getLeadsModelViewInfo: (uid, param, appId, adUserType) => {
      // 微信直客 查看关联账户的所有线索、
      if (appId && adUserType == 1) {
        const url = `${'/api/v1/leads/associate_list' + '?accountId='}${uid}`;
        return doRPC('POST', url, param);
      }
      return doRPC('POST', `${'/api/v1/leads/list' + '?accountId='}${uid}`, param);
    },
    getLog: params => doRPC('GET', '/api/v2/operation_log/list', params),
    getLeadsContactInfo: (uid, leadsId) => doRPC('GET', `${'/api/v1/leads/contact' + '?accountId='}${uid}&leadsId=${leadsId}`, {}),

    getLeadsOneToOneTagInfo: (uid, leadsId) => doRPC('GET', `${'/api/v1/leads/tag' + '?accountId='}${uid}&leadsId=${leadsId}`, {}),

    getLeadsBaseInfo: (uid, leadsId) => doRPC('GET', `${'/api/v1/leads/base' + '?accountId='}${uid}&leadsId=${leadsId}`, {}),

    getLeadsActionInfo: (uid, leadsId) => doRPC('GET', `${'/api/v1/leads/action' + '?accountId='}${uid}&leadsId=${leadsId}`, {}),

    getLeadsFollowInfo: (uid, leadsId, pageInfo) => doRPC('GET', `${'/api/v1/leads/follow_up_record' + '?accountId='}${uid}&leadsId=${leadsId}&pageIndex=${pageInfo?.pageIndex}&pageSize=${pageInfo?.pageSize}`, {}),

    getLeadsCallRecordInfo: (uid, leadsId, pageInfo) => doRPC('GET', `${'/api/v1/leads/call_record' + '?accountId='}${uid}&leadsId=${leadsId}&pageIndex=${pageInfo?.pageIndex}&pageSize=${pageInfo?.pageSize}`, {}),

    getLeadsCallRecordUrl: (leadsId, recordId, recordLeadsId) => doRPC(
      'GET',
      `${'/api/v1/leads/call_record_url' + '?accountId='}${window.pageProps.uid
      }&leadsId=${leadsId}&recordId=${recordId}&recordLeadsId=${recordLeadsId}`,
      {},
    ),

    getLeadsPushRecordInfo: leadsId => doRPC('GET', `${'/api/v1/leads/push_record' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {}),

    /** 根据线索id获取线索洞察信息接口 */
    getInsightDetail: data => doRPC('GET', `${'/api/v2/leads/insight/detail' + '?accountId='}${window.pageProps.uid}&leadsId=${data.leadsId}`, {}),

    /** 更新线索洞察信息有效状态 */
    updateInsightUseful: ({ leadsId, useful }) => doRPC('POST', `${'/api/v2/leads/insight/update_useful' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {
      useful,
    }),

    updateLeadsIntentionTag: (uid, leadsId, tagId) => doRPC('POST', `${'/api/v1/leads/intention_tag' + '?accountId='}${uid}&leadsId=${leadsId}`, {
      tagId,
    }),
    updateLeadsIntentionTags: (uid, leadsIds, tagId) => doRPC('POST', `${'/api/v1/leads/intention_tag_batch' + '?accountId='}${uid}`, {
      leadsIds,
      tagId,
    }),
    updateLeadsBatchMark: (uid, uploadedFile) => doRPC('POST', `${'/api/v1/leads/confirm_batch_mark' + '?accountId='}${window.pageProps.uid}`, {
      uploadedFile,
    }),
    updateLeadsImport: (uid, uploadedFile) => doRPC('POST', `${'/api/v1/leads/confirm_leads_import' + '?accountId='}${window.pageProps.uid}`, uploadedFile),
    getOfflineTasks: pageInfo => doRPC('POST', `/api/v1/offline_task/list?accountId=${window.pageProps.uid}`, { page_info: pageInfo }),
    updateSOPLeadsImport: (uid, uploadedFile) => doRPC('POST', `${'/api/v1/leads/confirm_defeat_leads_import' + '?accountId='}${window.pageProps.uid}`, {
      uploadedFile,
    }),

    updateLeadsFollowTag: (leadsId, tagId) => doRPC('POST', `${'/api/v1/leads/follow_tag' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {
      tagId,
      leadsStatus2: tagId,
    }),
    updateLeadsFollowTags: (leadsIds, tagId) => doRPC('POST', `${'/api/v1/leads/follow_tag_batch' + '?accountId='}${window.pageProps.uid}`, {
      leadsIds,
      tagId,
      leadsStatus2: tagId,
    }),
    updateLeadsContactInfo: (uid, leadsId, paramType, value) => doRPC('POST', `${'/api/v1/leads/update_contact' + '?accountId='}${uid}&leadsId=${leadsId}`, {
      paramType,
      value,
    }),

    insertLeadsFollowRecord: (uid, leadsId, value) => doRPC('PUT', `${'/api/v1/leads/follow_up_record' + '?accountId='}${uid}&leadsId=${leadsId}`, {
      detail: value,
    }),

    getBatchDistributeConfig: () => doRPC(
      'GET',
      `/api/v1/leads/get_manual_batch_distribute_config?accountId=${window.pageProps.uid}`,
      {},
    ),

    leadsBatchDistribute: params => doRPC(
      'POST',
      `/api/v1/leads/save_config_and_batch_distribute?accountId=${window.pageProps.uid}`,
      params,
    ),

    distributeLeads: (leadsIds, targetOwner) => doRPC('POST', `${'/api/v1/leads/distribute' + '?accountId='}${window.pageProps.uid}`, {
      leadsIds,
      targetOwnerId: targetOwner,
    }),

    updateLeadsInfoField: (paramType, value, leadsId) => doRPC('POST', `${'/api/v1/leads/update_field' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {
      paramType,
      value,
    }),

    getLeadsContactAction: leadsId => doRPC(
      'GET',
      `${'/api/v1/leads/leads_contact_action' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`,
      {},
    ),

    updateLeadsBundle: (leadsId, fieldName, fieldValue) => doRPC('POST', `${'/api/v1/leads/leads_bundle' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {
      fieldName,
      fieldValue,
    }),

    getConversion_claim_agg: leadsId => doRPC(
      'GET',
      `${'/api/v1/leads/conversion_claim_agg' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`,
      {},
    ),

    // 修改线索自定义属性
    updateLeadsCustomizedAttributes: (leadsId, name, value) => doRPC(
      'POST',
      `${'/api/v1/leads/update_customized_attribute' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`,
      {
        name,
        value,
      },
    ),
    // getLeadsLabel: (leadsId) =>
    //   doRPC('GET', `${'/api/v1/leads_tag/get' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {}),
    addLeadsLabel: (leadsId, tagIds) => doRPC('POST', `${'/api/v1/leads/add_tag_relation' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`, {
      tagIds,
    }),
    updateLeadsLabel: (leadsId, tagIds) => doRPC(
      'POST',
      `${'/api/v1/leads/update_tag_relation' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`,
      { tagIds },
    ),
    batchUpdateLeadsLabel: (leadsIds, tagIds) => doRPC('POST', `${'/api/v1/leads/update_tag_relation_batch' + '?accountId='}${window.pageProps.uid}`, {
      leadsIds,
      tagIds,
    }),
    deleteLeadsLabel: (leadsId, tagIds) => doRPC(
      'POST',
      `${'/api/v1/leads/delete_tag_relation' + '?accountId='}${window.pageProps.uid}&leadsId=${leadsId}`,
      { tagIds },
    ),
    getPreviewUrl: (leadsId, pageId) => doRPC(
      'GET',
      `/api/v1/leads/wechat_landing_page_preview_url?accountId=${window.pageProps.uid}&leadsId=${leadsId}&pageId=${pageId}`,
      {},
    ),
    getUniqueStatus: () => doRPC('GET', '/api/v1/leads/list_deduplication_status', { accountId: window.pageProps.uid }),
    setUniqueStatus: leadsListDeduplication => doRPC(
      'PUT',
      `/api/v1/leads/list_deduplication_status?accountId=${window.pageProps.uid}&leadsListDeduplication=${leadsListDeduplication}`,
      {},
    ),
    getLeadsCount: ownerIds => doRPC('POST', `/api/v1/leads/count?accountId=${window.pageProps.uid}`, { ownerIds }),
    getTmcConfig: () => {
      const url = `/api/v1/account_settings/get_tmc_notification?accountId=${window.pageProps.uid}&notificationSettingsType=LEADS_COMMUNICATE_AGAIN_NOTIFY_OWNER`;
      return doRPC('GET', url, {});
    },
    setClaimStatus: (params = {}) => doRPC(
      'POST',
      // deprecated
      // `/api/v1/account_settings/claim_rules/update_status?accountId=${window.pageProps.uid}`,
      `/api/v1/account_settings/reclaim_rules/update_config?accountId=${window.pageProps.uid}`,
      params,
    ),
    getRecycleStatus: () => doRPC(
      'GET',
      // deprecated
      // `/api/v1/account_settings/reclaim_rules/get_status?accountId=${window.pageProps.uid}`,
      `/api/v1/account_settings/reclaim_rules/get_config?accountId=${window.pageProps.uid}`,
      {},
    ),
    setRecycleStatus: (params = {}) => doRPC(
      'POST',
      // deprecated
      // `/api/v1/account_settings/reclaim_rules/update_status?accountId=${window.pageProps.uid}`,
      `/api/v1/account_settings/reclaim_rules/update_config?accountId=${window.pageProps.uid}`,
      params,
    ),
    getRecycleRule: () => doRPC(
      'GET',
      `/api/v1/account_settings/reclaim_rules/get?accountId=${window.pageProps.uid}`,
      {},
    ),
    createRecycleRule: (params = {}) => doRPC(
      'POST',
      `/api/v1/account_settings/reclaim_rules/create?accountId=${window.pageProps.uid}`,
      params,
    ),
    updateRecycleRule: (params = {}) => doRPC(
      'POST',
      `/api/v1/account_settings/reclaim_rules/update?accountId=${window.pageProps.uid}`,
      params,
    ),
    reclaim: (params = {}) => doRPC(
      'POST',
      `/api/v1/leads/reclaim?accountId=${window.pageProps.uid}`,
      params,
    ),
    claim: (params = {}) => doRPC(
      'POST',
      `/api/v1/leads/claim?accountId=${window.pageProps.uid}`,
      params,
    ),
    accountCallRecords: (params = {}) => doRPC(
      'POST',
      `/api/v1/call_record/account_call_records?accountId=${window.pageProps.uid}`,
      params,
    ),
    getTodoCount: (params = {}) => doRPC(
      'GET',
      `/api/v1/leads/get_todo_statistics?accountId=${window.pageProps.uid}`,
      params,
    ),
    updatePlanFollowTime: (leadsId, params = {}) => doRPC(
      'POST',
      `/api/v1/leads/update_plan_follow_time?accountId=${window.pageProps.uid}&leadsId=${leadsId}`,
      params,
    ),
    getIntentionFormActionOptions: (params = {}) => doRPC(
      'POST',
      `/api/v1/leads/list_intention_form_actions?accountId=${window.pageProps.uid}`,
      params,
    ),
  },
  third: {
    authResult: ({ redirectUrl, appId, thirdName, outerAccountId, outerAccountName, authStatus }) => doRPC('POST', `/third/authresult?accountId=${window.pageProps.uid}`, {
      redirectUrl,
      appId,
      outerAccountId,
      outerAccountName,
      authStatus,
      thirdName,
    }),
  },
  componentReport: {
    getConsultEffectList: params => doRPC('GET', `/api/v1/leads/consult_list?accountId=${window.pageProps.uid}`, params),
    getConsultStatList: params => doRPC('GET', `/api/v1/leads/effect_consult_stat?accountId=${window.pageProps.uid}`, params),
    getConsultContent: leadsId => doRPC('GET', `/api/v1/leads/consult_content?accountId=${window.pageProps.uid}&leadsId=${leadsId}`),
    getSpeechList: (params = {}) => doRPC('GET', `/api/v1/customer_service_script/list?accountId=${window.pageProps.uid}`, params), // 话术列表
    getSpeechDetail: id => doRPC('GET', `/api/v1/customer_service_script/detail?accountId=${window.pageProps.uid}&id=${id}`), // 话术详情
    copySpeech: (params = {})  => doRPC('POST', `/api/v1/customer_service_script/copy?accountId=${window.pageProps.uid}`, params), // 复制话术
    addSpeech: (params = {}) => doRPC('POST', `/api/v1/customer_service_script/create?accountId=${window.pageProps.uid}`, params), // 新建话术
    updateSpeech: (params = {}) => doRPC('POST', `/api/v1/customer_service_script/update?accountId=${window.pageProps.uid}`, params), // 更新话术
    checkContent: (params = {}) => doRPC('POST', `/api/v1/info_sec/check_content?accountId=${window.pageProps.uid}`, params), // 文本校验
    getConsultDeaultList: industry => doRPC('GET', `/api/v1/customer_service_script/default/list?accountId=${window.pageProps.uid}&industry=${industry}`),
    copySetting: (params = {})  => doRPC('POST', `/api/v1/consult/setting_copy?accountId=${window.pageProps.uid}`, params), // 复制当前账户的会话设置规则，包括上报城市，上报标签的规则
    reportSetting: (params = {})  => doRPC('POST', `/api/v1/customer_service_script/update_consult_setting?accountId=${window.pageProps.uid}`, params), // 上报设置
    consultSetting: (params = {})  => doRPC('POST', `/api/v1/consult/setting?accountId=${window.pageProps.uid}`, params), // 用户会话设置新增
    consultSettingEdit: (params = {})  => doRPC('PUT', `/api/v1/consult/setting?accountId=${window.pageProps.uid}`, params), // 用户会话设置新增
    geConsultSetting: (params = {})  => doRPC('GET', `/api/v1/consult/setting?accountId=${window.pageProps.uid}`, params), // 获取用户当前的会话设置
    getQuestionAnswerList: (params = {})  => doRPC('GET', `/api/v1/question_answer/list?accountId=${window.pageProps.uid}`, params), // 问答库管理列表
    createQuestionAnswer: (params = {}) => doRPC('POST', `/api/v1/question_answer/create?accountId=${window.pageProps.uid}`, params), // 新建问答库
    updateQuestionAnswer: (params = {}) => doRPC('POST', `/api/v1/question_answer/update?accountId=${window.pageProps.uid}`, params), // 更新问答库
    deleteQuestionAnswer: categoryId => doRPC('POST', `/api/v1/question_answer/delete?accountId=${window.pageProps.uid}&categoryId=${categoryId}`), // 删除问答库
    getStatisticChat: params => doRPC('GET', `/api/v1/chatbot/statistics/chat?accountId=${window.pageProps.uid}`, params),
    getStatisticsGreeting: params => doRPC('GET', `/api/v1/chatbot/statistics/greeting?accountId=${window.pageProps.uid}`, params),
    getStatisticsGuest: params => doRPC('GET', `/api/v1/chatbot/statistics/guest?accountId=${window.pageProps.uid}`, params),
  },

  employeeManage: {
    checkChatsExist: userId => doRPC('GET', `/api/v1/employee/check_chats_exist?accountId=${window.pageProps.uid}&userId=${userId}`),
    getEmployees: (name, qq, tel, userGroupName, pageSize, pageIndex, status, wxId) => {
      const data = {
        name,
        qq,
        wxId,
        tel,
        userGroupName,
        pageSize,
        pageIndex,
      };
      if (status !== undefined) {
        data.status = status;
      }
      return doRPC('GET', `/api/v1/employee/list?accountId=${window.pageProps.uid}`, data);
    },

    // 获取归属人列表
    getEmployeesList: data => doRPC('GET', `/api/v1/employee/list?accountId=${window.pageProps.uid}`, data),

    getEmployeeById: userId => doRPC('GET', `/api/v1/employee/get?accountId=${window.pageProps.uid}`, { userId }),

    updateEmployee: (userId, name, role, tel, userGroupId, dataPrivilegeGroupIdList = []) => doRPC('POST', `/api/v1/employee/update?accountId=${window.pageProps.uid}&userId=${userId}`, {
      name,
      role,
      tel,
      userGroupId,
      dataPrivilegeGroupIdList,
    }),

    updateEmployeeStatus: (userId, status) => doRPC('POST', `/api/v1/employee/update_status?accountId=${window.pageProps.uid}&userId=${userId}`, {
      status,
    }),
    updateEmployeeSchedule: ({ userId, weekPeriodDetails = [], enableWeekPeriodDetail = false, weekPeriod }) => doRPC('POST', `/api/v1/employee/update_schedule?accountId=${window.pageProps.uid}&userId=${userId}`, {
      weekPeriodDetails, enableWeekPeriodDetail, weekPeriod,
    }),

    updateLeadsReceiveStatus: (userId, status) => doRPC('POST', `/api/v1/employee/update_leads_receive_status?accountId=${window.pageProps.uid}&userId=${userId}`, {
      status,
    }),

    updateLeadsReceiveLimit: (userId, limit) => doRPC(
      'POST',
      `/api/v1/employee/update_leads_receive_upper_limit?accountId=${window.pageProps.uid}&userId=${userId}`,
      { leadsReceiveUpperLimit: limit },
    ),

    insertEmployee: (name, loginType, qq, wxId, role, tel, userGroupId, dataPrivilegeGroupIdList = []) => doRPC('POST', `/api/v1/employee/add?accountId=${window.pageProps.uid}`, {
      name,
      type: loginType,
      qq,
      wxId,
      role,
      tel,
      userGroupId,
      dataPrivilegeGroupIdList,
    }),
    deleteEmployee: (userId, leadsTransferStrategy, targetUserId) => doRPC(
      'POST', `/api/v1/employee/delete?accountId=${window.pageProps.uid}&userId=${userId}`,
      { ...leadsTransferStrategy && { leadsTransferStrategy }, ...targetUserId && { targetUserId } },
    ),

    addUserGroup: (groupName, userIds) => doRPC('POST', `/api/v1/user_group/add?accountId=${window.pageProps.uid}`, {
      groupName,
      userIds,
    }),
    updateUserGroup: (userGroupId, groupName, userIds) => doRPC('POST', `/api/v1/user_group/update?accountId=${window.pageProps.uid}`, {
      userGroupId,
      groupName,
      userIds,
    }),
    getGroupList: (pageSize, pageIndex, groupName) => doRPC('POST', `/api/v1/user_group/list?accountId=${window.pageProps.uid}`, {
      ...(pageSize && pageIndex && { pageSize }),
      ...(pageSize && pageIndex && { pageIndex }),
      groupName,
    }),
    getSimpleGroupList: data => doRPC('POST', `/api/v1/user_group/simple_list?accountId=${window.pageProps.uid}`, data),
    getGroupById: userGroupId => doRPC('POST', `/api/v1/user_group/user/list?accountId=${window.pageProps.uid}`, {
      userGroupId,
    }),
    deleteGroup: userGroupId => doRPC('POST', `/api/v1/user_group/delete?accountId=${window.pageProps.uid}`, {
      userGroupId,
    }),
    groupInDistributeRule: userGroupId => doRPC('POST', `/api/v1/user_group/is_in_distribution_rule?accountId=${window.pageProps.uid}`, {
      userGroupId,
    }),
    employeeInDistributeRule: userId => doRPC('POST', `/api/v1/employee/is_in_distribute_rule?accountId=${window.pageProps.uid}`, {
      userId,
    }),

  },

  sso: {
    getLoginStateInfo: () => doRPC('GET', '/api/v1/login/login_state', {}),
    logout: () => doRPC('DELETE', '/api/v1/login/logout', {}),
    getSecondLoginStateInfo: () => doRPC('GET', `/api/v1/login/second_login_state?accountId=${window.pageProps.uid}`, {}),
    secondLoginCheck: password => doRPC('POST', `/api/v1/login/second_login_check?accountId=${window.pageProps.uid}`, {
      password,
    }),
    getLoginUserList: params => doRPC('POST', `/api/v1/login/get_account_list_for_login_user?accountId=${window.pageProps.uid}`, params),
    // login/get_account_list_for_login_user
  },

  checkLogin: {
    advertiserPageLogin: (params = {}) => doRPC('GET', `/api/v1/login/check_login_state?accountId=${window.pageProps.uid}`, params),
  },
  landingPage: {
    getLandingPageInfos: () => doRPC('GET', `/api/v1/landing_page/list?accountId=${window.pageProps.uid}`, {}),
  },

  account: {
    qwPlatformGetLoginUserList: () => doRPC('GET', `/api/v1/advertisers/list?accountId=${window.pageProps.uid}`, {}),
    sendPsw: () => doRPC('GET', `/api/v1/account/send_password?accountId=${window.pageProps.uid}`, {}),
    sendSMSCode: (mobile, type) => doRPC('POST', `/api/v1/account/send_sms_code?accountId=${window.pageProps.uid}`, {
      mobile,
      type,
    }),
    createAccountPassword: (mobile, smsCode, password) => doRPC('POST', `/api/v1/account/create_password?accountId=${window.pageProps.uid}`, {
      mobile,
      smsCode,
      password,
    }),
    updateAccountPassword: (password, newPassword) => doRPC('POST', `/api/v1/account/update_password?accountId=${window.pageProps.uid}`, {
      password,
      newPassword,
    }),
    updateAccountPswMobile: (mobile, smsCode) => doRPC('POST', `/api/v1/account/update_psw_mobile?accountId=${window.pageProps.uid}`, {
      mobile,
      smsCode,
    }),
    check_whitelist: (accountId, whitelistName) => doRPC('GET', `/api/v1/account/check_whitelist?accountId=${window.pageProps.uid}`, {
      accountId,
      whitelistName,
    }),
    deleteAccountPsw: () => doRPC('POST', `/api/v1/account/delete_psw?accountId=${window.pageProps.uid}`, {}),
    getPasswordInfo: () => doRPC('GET', `/api/v1/account/get_password_info?accountId=${window.pageProps.uid}`, {}),
    getEncryptionConfig: () => doRPC('GET', `/api/v1/account/get_encryption_config?accountId=${window.pageProps.uid}`, {}),
    updateEncryptionConfig: (roleId, encryptOrNot) => doRPC('POST', `/api/v1/account/update_encryption_config?accountId=${window.pageProps.uid}`, {
      roleId,
      encryptOrNot,
    }),
    updateQwEncryptionConfig: (roleId, encryptOrNot) => doRPC('POST', `/api/v1/account/update_wxwork_encryption_config?accountId=${window.pageProps.uid}`, {
      roleId,
      encryptOrNot,
    }),
    getEditReceiveLeadsConfig: () => doRPC('GET', `/api/v1/account/get_leads_receive_status_manage?accountId=${window.pageProps.uid}`, {}),
    updateEditReceiveLeadsConfig: (roleId, leadsReceiveStatusManage) => doRPC('POST', `/api/v1/account/set_leads_receive_status_manage?accountId=${window.pageProps.uid}`, {
      roleId,
      leadsReceiveStatusManage,
    }),
    getLeadsDownloadConfig: () => doRPC('GET', `/api/v1/account/get_leads_download_config?accountId=${window.pageProps.uid}`, {}),
    updateLeadsDownloadConfig: (roleId, leadsDownloadStatus) => doRPC('POST', `/api/v1/account/set_leads_download_config?accountId=${window.pageProps.uid}`, {
      roleId,
      leadsDownloadStatus,
    }),
    // 查询姓名脱敏状态
    getNameEncryptionConfig: () => doRPC('GET', `/api/v1/account/get_leads_name_masking_config?accountId=${window.pageProps.uid}`, {}),
    // 更新姓名脱敏状态
    updateNameEncryptionConfig: (roleId, nameMaskingConfig) => doRPC('POST', `
    	/api/v1/account/set_leads_name_masking_config?accountId=${window.pageProps.uid}`, {
      roleId,
      nameMaskingConfig,
    }),
    // 获取独立密码
    getPassword: (mobile, smsCode) => doRPC('POST', `
    /api/v2/account/get_password?accountId=${window.pageProps.uid}`, {
      mobile,
      smsCode,
    }),
    // 更新账户备注信息 仅管理员
    updateAccountRemark: accountRemark => doRPC('POST', `/api/v2/account/update_remark?accountId=${window.pageProps.uid}`, { accountRemark }),
  },
  leadsOrder: {
    canUseSmartOrder: () => {
      const url = `${'/api/v1/leads_order/can_use_smart_order' + '?accountId='}${window.pageProps.uid}`;
      return doRPC('GET', url, {});
    },
  },
  accountSettings: {
    getLeadsDistri() {
      return doRPC('GET', `/api/v1/account_settings/get_leads_distri?accountId=${window.pageProps.uid}`, {});
    },
    setLeadsDistri(leadsDistriSettingsStr, employeesStr) {
      return doRPC('POST', `/api/v1/account_settings/set_leads_distri?accountId=${window.pageProps.uid}`, {
        leadsDistriSettingsStr,
        employeesStr,
      });
    },
    getNotification() {
      return doRPC('GET', `/api/v1/account_settings/get_notification?accountId=${window.pageProps.uid}`, {});
    },
    getShanxinCompanyNames() {
      return doRPC('GET', `/api/v1/account_settings/get_flashletter_company_name?accountId=${window.pageProps.uid}`, {});
    },
    setShanxinCompanyName(companyName) {
      return doRPC('POST', `/api/v1/account_settings/update_flashletter_company_name?accountId=${window.pageProps.uid}&companyName=${companyName}`, {});
    },
    setFlashletterNotification(flashletterNotificationStr) {
      return doRPC(
        'POST',
        `/api/v1/account_settings/set_flashletter_notification?accountId=${window.pageProps.uid}&flashletterNotificationStr=${flashletterNotificationStr}`,
        {},
      );
    },
    setNotification(notificationSettingsStr) {
      return doRPC(
        'POST',
        `/api/v1/account_settings/set_notification?accountId=${window.pageProps.uid}&notificationSettingsStr=${notificationSettingsStr}`,
        {},
      );
    },
    setUserNotification(notificationSettingsStr) {
      return doRPC(
        'POST',
        `/api/v1/account_settings/set_user_notification?accountId=${window.pageProps.uid}&notificationToUserStr=${notificationSettingsStr}`,
        {},
      );
    },
    getBindingState() {
      return doRPC('GET', `/api/v1/wechat_binding/get?accountId=${window.pageProps.uid}`, {});
    },
    createQRCode() {
      return doRPC('POST', `/api/v1/wechat_binding/create_qr_code?accountId=${window.pageProps.uid}`, {});
    },
    unBindWeChat() {
      return doRPC('POST', `/api/v1/wechat_binding/unbind?accountId=${window.pageProps.uid}`, {});
    },
    getCustomRules() {
      return doRPC('GET', `/api/v1/account_settings/get_custom_rules?accountId=${window.pageProps.uid}`, {});
    },
    setCustomRules(rules, settingName, customizedAttributeNames = []) {
      return doRPC('POST', `/api/v1/account_settings/set_custom_rules?accountId=${window.pageProps.uid}`, {
        customizedColumns: rules,
        settingName,
        customizedAttributeNames,
      });
    },
    updateCustomRules(rules, settingName, settingOldName, customizedAttributeNames = []) {
      return doRPC('POST', `/api/v1/account_settings/update_custom_rules?accountId=${window.pageProps.uid}`, {
        customizedColumns: rules,
        settingName,
        settingOldName,
        customizedAttributeNames,
      });
    },
    deleteCustomRules(settingName) {
      return doRPC('POST', `/api/v1/account_settings/remove_custom_rules?accountId=${window.pageProps.uid}`, {
        settingName,
      });
    },
    // 获取所有自定义属性
    getCustomizedAttributes() {
      return doRPC('GET', `/api/v1/account_settings/get_customized_attributes?accountId=${window.pageProps.uid}`, {});
    },
    getIndustry: () => doRPC(
      'GET',
      `/api/v1/account_settings/get_leads_status_system_option?accountId=${window.pageProps.uid}`,
      {},
    ),
    setIndustry: (params = {}) => doRPC(
      'POST',
      `/api/v1/account_settings/set_leads_status_system_option?accountId=${window.pageProps.uid}`,
      params,
    ),
    getIndustryTree: () => doRPC(
      'GET',
      `/api/v1/industry_status/get_leads_industry_status?accountId=${window.pageProps.uid}`,
      {},
    ),
    getIndustryOption: () => doRPC(
      'GET',
      `/api/v1/industry_status/get_current_leads_status_tree?accountId=${window.pageProps.uid}`,
      {},
    ),
  },
  bussinessSetting: {
    getLeadsMapping: (pageSize, pageIndex) => doRPC('GET', `/api/v1/leads_mapping/list?accountId=${window.pageProps.uid}`, {
      pageSize,
      pageIndex,
    }),
    getAllLeadsMapping: () => doRPC('GET', `/api/v1/leads_mapping/list?accountId=${window.pageProps.uid}`),
    getLeadsMappingOne: mappingId => doRPC('GET', `/api/v1/leads_mapping/get?accountId=${window.pageProps.uid}`, { mappingId }),
    deleteMapping: mappingId => doRPC('GET', `/api/v1/leads_mapping/delete?accountId=${window.pageProps.uid}`, { mappingId }),
    // uploadMappingExcel:() => {
    //     return doRPC("POST", "/api/v1/leads_mapping/upload_excel?accountId="
    // 	    + window.pageProps.uid, {});
    // }
    confirmMappingExcel: ({
      uploadedExcelPath,
      originalFieldName,
      mappedFieldName,
      originalFieldType,
      mappingDetailUploadMethod,
      mappingDetail,
    }) => doRPC('POST', `/api/v1/leads_mapping/confirm_excel?accountId=${window.pageProps.uid}`, {
      uploadedExcelPath,
      originalFieldName,
      mappedFieldName,
      originalFieldType,
      mappingDetailUploadMethod,
      ...(mappingDetailUploadMethod === 1 ? { mappingDetail } : null),
    }),
    downloadMapping: mappingId => doRPC('GET', `/api/v1/leads_mapping/download?accountId=${window.pageProps.uid}`, { mappingId }),
    update: data => doRPC('POST', `/api/v1/leads_mapping/update?accountId=${window.pageProps.uid}`, data),
  },
  noticeCenter: {
    getCategory: params => doRPC('POST', `/api/v1/notice_category/get?accountId=${window.pageProps.uid}`, params),
    getList: params => doRPC('POST', `/api/v1/notice/list?accountId=${window.pageProps.uid}`, params),
    getNoticeDetail: params => doRPC('POST', `/api/v1/notice/get?accountId=${window.pageProps.uid}`, params),
    readNotice: params => doRPC('POST', `/api/v1/notice/read?accountId=${window.pageProps.uid}`, params),
  },
  leadsFieldSplitting: {
    getAll: () => doRPC('POST', `/api/v1/leads_field_splitting/list?accountId=${window.pageProps.uid}`, {}),
    add: params => doRPC('POST', `/api/v1/leads_field_splitting/add?accountId=${window.pageProps.uid}`, params),
    update: params => doRPC('POST', `/api/v1/leads_field_splitting/update?accountId=${window.pageProps.uid}`, params),
    delete: params => doRPC('POST', `/api/v1/leads_field_splitting/delete?accountId=${window.pageProps.uid}`, params),
  },

  leadsPushRule: {
    getAll: () => doRPC('POST', `/api/v1/leads_push_rule/list?accountId=${window.pageProps.uid}`, {}),
    add: params => doRPC('POST', `/api/v1/leads_push_rule/add?accountId=${window.pageProps.uid}`, params),
    update: params => doRPC('POST', `/api/v1/leads_push_rule/update?accountId=${window.pageProps.uid}`, params),
    delete: params => doRPC('POST', `/api/v1/leads_push_rule/delete?accountId=${window.pageProps.uid}`, params),
    pause: params => doRPC('POST', `/api/v1/leads_push_rule/pause?accountId=${window.pageProps.uid}`, params),
    testUrl: params => doRPC('POST', `/api/v1/leads_push_rule/test_push_url?accountId=${window.pageProps.uid}`, params),
    example: params => doRPC('POST', `/api/v1/leads_push_rule/example?accountId=${window.pageProps.uid}`, params),
    test: params => doRPC('POST', `/api/v1/leads_push_rule/test_push?accountId=${window.pageProps.uid}`, params),
  },
  tips: {
    get: () => doRPC('GET', `/api/v1/tips/get?accountId=${window.pageProps.uid}`, null),
    nextTime: tipsCode => doRPC('POST', `/api/v1/tips/next_time?accountId=${window.pageProps.uid}`, { tipsCode }),
    noLonger: tipsCode => doRPC('POST', `/api/v1/tips/no_longer?accountId=${window.pageProps.uid}`, { tipsCode }),
  },
  leadsSetting: {
    get: () => doRPC('GET', `/api/v1/leads_distri_setting/get?accountId=${window.pageProps.uid}`, null),
    set: params => doRPC('POST', `/api/v1/leads_distri_setting/set_leads_distri_type?accountId=${window.pageProps.uid}`, params),
    getBasicRule: () => doRPC('GET', `/api/v1/leads_distri_setting/get_basic_rule?accountId=${window.pageProps.uid}`, null),
    updateBasicRule: params => doRPC('POST', `/api/v1/leads_distri_setting/update_basic_rule?accountId=${window.pageProps.uid}`, params),
    getCustomizedRules: paramStr => doRPC('POST', `/api/v1/leads_distri_setting/list_customized_rule?accountId=${window.pageProps.uid}${paramStr}`, {}),
    updateCustomizedRules: params => doRPC('POST', `/api/v1/leads_distri_setting/update_customized_rule?accountId=${window.pageProps.uid}`, params),
    addCustomizedRules: params => doRPC('POST', `/api/v1/leads_distri_setting/add_customized_rule?accountId=${window.pageProps.uid}`, params),
    deleteCustomizedRules: params => doRPC('POST', `/api/v1/leads_distri_setting/delete_customized_rule?accountId=${window.pageProps.uid}`, params),
    sortCustomizedRules: params => doRPC('POST', `/api/v1/leads_distri_setting/sort_customized_rule?accountId=${window.pageProps.uid}`, params),
    // 自定义分配规则状态更新
    ruleStatusUpdate: params => doRPC('POST', `/api/v1/leads_distri_setting/pause_customized_rule?accountId=${window.pageProps.uid}`, params),
    setDistriDeduplicate: params => doRPC('POST', `/api/v1/leads_distri_setting/set_distri_deduplicate?accountId=${window.pageProps.uid}`, params),
    setDistriSame: params => doRPC('POST', `/api/v1/leads_distri_setting/set_leads_unified_distribute_status?accountId=${window.pageProps.uid}`, params),
    getLeadsPushUrl: (params = {}) => doRPC('GET', `/api/v1/leads/get_leads_receive_url?accountId=${window.pageProps.uid}`, params),
    getLeadsDistributeConfig: (params = {}) => doRPC('GET', `/api/v1/leads_distri_setting/get_leads_distri_type?accountId=${window.pageProps.uid}`, params),
    setLeadsDistributeConfig: (params = {}) => doRPC('POST', `/api/v1/leads_distri_setting/set_distri_deduplicate?accountId=${window.pageProps.uid}`, params),
  },
  labelGroupManage: {
    getGroups: (params = {}) => doRPC('POST', `/api/v1/tag_group/list?accountId=${window.pageProps.uid}`, params),
    addGroup: params => doRPC('POST', `/api/v1/customized_tag_group/add?accountId=${window.pageProps.uid}`, params),
    updateGroup: params => doRPC('POST', `/api/v1/customized_tag_group/update?accountId=${window.pageProps.uid}`, params),
    deleteGroup: params => doRPC('POST', `/api/v1/customized_tag_group/delete?accountId=${window.pageProps.uid}`, params),
    resetGroup: params => doRPC('POST', `/api/v1/tag_group/list/order/reset?accountId=${window.pageProps.uid}`, params),
  },
  labelManage: {
    getLabels: params => doRPC('POST', `/api/v1/tag/list?accountId=${window.pageProps.uid}`, params),
    addLabel: params => doRPC('POST', `/api/v1/customized_tag/add?accountId=${window.pageProps.uid}`, params),
    updateLabel: params => doRPC('POST', `/api/v1/customized_tag/update?accountId=${window.pageProps.uid}`, params),
    deleteLabel: params => doRPC('POST', `/api/v1/customized_tag/delete?accountId=${window.pageProps.uid}`, params),
  },
  quickHelp: {
    getCatalog: catalogId => doRPC('POST', `/api/v1/quick_help/catalog/get?accountId=${window.pageProps.uid}`, {
      catalog_id: catalogId,
    }),
    getPostList: catalogId => doRPC('POST', `/api/v1/quick_help/post/get_list?accountId=${window.pageProps.uid}`, {
      catalog_id: catalogId,
    }),
  },
  leadsCleaning: {
    getIndustryList: () => doRPC('GET', `/api/v1/auto_call/industry_category_list?accountId=${window.pageProps.uid}`, null),
    getTalkList: () => doRPC('GET', `/api/v1/auto_call/talk_list?accountId=${window.pageProps.uid}`, null),
    getRuleList: () => doRPC('GET', `/api/v1/auto_call/rule_list?accountId=${window.pageProps.uid}`, null),
    updateRuleList: params => doRPC('POST', `/api/v1/auto_call/rule_operate_batch?accountId=${window.pageProps.uid}`, params),
  },
  multiaccount: {
    getBindList: params => doRPC('GET', `/api/v1/multiaccount_manage/bind_list?accountId=${window.pageProps.uid}`, params),
    getBindHistory: params => doRPC('GET', `/api/v1/multiaccount_manage/bind_history?accountId=${window.pageProps.uid}`, params),
    getAuthList: params => doRPC('GET', `/api/v1/multiaccount_manage/auth_list?accountId=${window.pageProps.uid}`, params),
    getAuthHistory: params => doRPC('GET', `/api/v1/multiaccount_manage/auth_history?accountId=${window.pageProps.uid}`, params),
    bindSubAccount: params => doRPC('POST', `/api/v1/multiaccount_manage/bind_sub_account?accountId=${window.pageProps.uid}`, params),
    withdrawBind: params => doRPC('POST', `/api/v1/multiaccount_manage/withdraw_bind?accountId=${window.pageProps.uid}`, params),
    confirmBind: params => doRPC('POST', `/api/v1/multiaccount_manage/confirm_bind?accountId=${window.pageProps.uid}`, params),
    denyBind: params => doRPC('POST', `/api/v1/multiaccount_manage/deny_bind?accountId=${window.pageProps.uid}`, params),
    unbindAccount: params => doRPC('POST', `/api/v1/multiaccount_manage/unbind_account?accountId=${window.pageProps.uid}`, params),
    withdrawUnbind: params => doRPC('POST', `/api/v1/multiaccount_manage/withdraw_unbind?accountId=${window.pageProps.uid}`, params),
    confirmUnbind: params => doRPC('POST', `/api/v1/multiaccount_manage/confirm_unbind?accountId=${window.pageProps.uid}`, params),
  },
  fansManagement: {
    list: () => doRPC('GET', `/api/v1/wx_app/list?accountId=${window.pageProps.uid}`, {}),
    verifiedList: () => doRPC('GET', `/api/v1/wx_app/verified_app_list?accountId=${window.pageProps.uid}`, {}),
    authorize: () => doRPC('GET', `/api/v1/wx_app/authorize?accountId=${window.pageProps.uid}`, {}),
    updateAuthorizeInfo: params => doRPC('POST', `/api/v1/wx_app/update_authorize_info?accountId=${window.pageProps.uid}`, params),
    confirmAuthorizer: (wxAppId, params) => doRPC('POST', `/api/v1/wx_app/confirm_authorizer?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    getPersonalWx: (params = {}) => doRPC(
      'POST',
      `/api/v1/personal_wechat/list?accountId=${window.pageProps.uid}`,
      params,
    ),
    updateFriendStatus: (params = {}) => doRPC(
      'POST',
      `/api/v1/personal_wechat/batch_update_status?accountId=${window.pageProps.uid}`,
      params,
    ),
    distributeFriend: (params = {}) => doRPC(
      'POST',
      `/api/v1/personal_wechat/distribute?accountId=${window.pageProps.uid}`,
      params,
    ),
  },
  wechatUserManagement: {
    list: (wxAppId, params) => doRPC('POST', `/api/v1/wx_app_user/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    updateTags: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_user/update_tags_batch?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    updateScores: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_user/update_intention_score_batch?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    getRegionList: () => doRPC('GET', `/api/v1/wx_app_user/get_region_list?accountId=${window.pageProps.uid}`, {}),
  },
  wechatTagManagement: {
    userTagList: (wxAppId, params) => doRPC('POST', `/api/v1/wechat_user_tag/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    add: (wxAppId, tagName) => doRPC('POST', `/api/v1/wechat_user_tag/add?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {
      tagName,
    }),
    update: (wxAppId, tagId, tagName) => doRPC('POST', `/api/v1/wechat_user_tag/update?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {
      tagId,
      tagName,
    }),
    delete: (wxAppId, tagId) => doRPC('POST', `/api/v1/wechat_user_tag/delete?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {
      tagId,
    }),
  },
  wechatRuleManagement: {
    ruleList: (wxAppId, params) => doRPC('POST', `/api/v1/wx_app_tag_recog_rule/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    delete: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_tag_recog_rule/delete?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    init: (wxAppId, params) => doRPC('POST', `/api/v1/wx_app_tag_recog_rule/init?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    add: (wxAppId, params) => doRPC('POST', `/api/v1/wx_app_tag_recog_rule/add?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    update: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_tag_recog_rule/update?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
  },
  messageSendingRule: {
    list: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_message_sending_rule/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    get: (wxAppId, ruleId) => doRPC('POST', `/api/v1/wx_app_message_sending_rule/get?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {
      ruleId,
    }),
    add: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_message_sending_rule/add?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    update: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_message_sending_rule/update?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    pause: (wxAppId, params) => doRPC(
      'POST',
      `/api/v1/wx_app_message_sending_rule/pause?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`,
      params,
    ),
    delete: (wxAppId, ruleId) => doRPC('POST', `/api/v1/wx_app_message_sending_rule/delete?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {
      ruleId,
    }),
  },
  wechatMaterial: {
    list: (wxAppId, params) => doRPC('POST', `/api/v1/wechat_material/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, params),
    getLandingPage: (advertiserId, pageId) => doRPC(
      'GET',
      `/api/v1/wechat_landing_page_preview_url/get?accountId=${window.pageProps.uid}&advertiserId=${advertiserId}&pageId=${pageId}`,
      {},
    ),
  },
  campaign: {
    list: (props = {}) => doRPC('POST', `/api/v1/campaign/list?accountId=${window.pageProps.uid}`, props),
  },
  mpCampaign: {
    list: wxAppId => doRPC('POST', `/api/v1/mp_campaign/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {}),
  },
  mpAdGroup: {
    list: wxAppId => doRPC('POST', `/api/v1/mp_adgroup/list?accountId=${window.pageProps.uid}&wxAppId=${wxAppId}`, {}),
  },
  pay: {
    getStatistic: time => doRPC('GET', `/api/v1/invalid_pay/statistics?accountId=${window.pageProps.uid}&time=${time}`, {}),
  },
  // 配置页面
  qwSystemConfig: {
    getSecret: () => doRPC(
      'GET',
      `/api/v1/wx_work_secret/get?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    setSecret: params => doRPC(
      'POST',
      `/api/v1/wx_work_secret/set?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    setChatSecret: params => doRPC(
      'POST',
      `/api/v1/wx_work_chat_secret/set?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    getPublicKey: () => doRPC(
      'GET',
      `/api/v1/wx_work_chat_publickey/get?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
  },
  // 企微侧边栏接口 侧边栏需要uid, （wxuserid不需要，登录态中有）, corpid
  qwSidebar: {
    getConfig: () => {
      if (!window.pageProps.corpId) {
        alert('missing corpId');
        return;
      }
      return doRPC(
        'GET',
        `/api/v1/wx_work_signature/get?uri=${encodeURIComponent(location.pathname + location.search)}&suiteIndex=1&accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
        {},
      );
    },
    // 客户管理页
    userGetList: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/get_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    editRemark: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_remark?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    editPhone: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_phone?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    getTags: () => doRPC(
      'GET',
      `/api/v1/wx_work_external_user/get_all_tags?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    editTags: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_tags?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    // 客户跟进页
    getFollowRecords: () => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/get_follow_records?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {
        pageIndex: 1,
        pageSize: 200,
        wxWorkUserId: window.pageProps.wxWorkUserId,
        externalUserId: window.pageProps.externalUserId,
      },
    ),
    EditFollowRecords: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_follow_status?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    // 消息提醒页
    noticeRuleList: () => doRPC(
      'POST',
      `/api/v1/wx_work_notice/notice_rule_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {
        noticeType: 2,
        pageIndex: 1,
        pageSize: 100,
        externalWxWorkUserId: window.pageProps.externalUserId,
        wxWorkUserId: window.pageProps.wxWorkUserId,
      },
    ),
    noticeAdd: params => doRPC(
      'POST',
      `/api/v1/wx_work_notice/follow_up_notice_rule_add?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    noticeDelete: params => doRPC(
      'POST',
      `/api/v1/wx_work_notice/notice_rule_delete?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
  },
  // 企微工作台接口, 需要 uid， （wxuserid不需要，登录态中有）, corpid, token
  qwBoard: {
    authList: () => doRPC('GET', `/api/v1/wx_work/list?accountId=${window.pageProps.uid}`, {}),
    corpList: () => doRPC('GET', `/api/v1/wx_work/access_corp_list?accountId=${window.pageProps.uid}`, {}),
    authorize: suiteIndex => doRPC('GET', `/api/v1/wx_work/together_auth_url?accountId=${window.pageProps.uid}`, { suiteIndex }),
    shareAuthList: () => doRPC('GET', `/api/v1/wx_work/share_auth/list?accountId=${window.pageProps.uid}`, {}),
    addExistAuthList: params => doRPC('POST', `/api/v1/wx_work/share_auth/create?accountId=${window.pageProps.uid}`, params || {}),
    editExistAuthList: params => doRPC('POST', `/api/v1/wx_work/share_auth/update?accountId=${window.pageProps.uid}`, params || {}),
  },
  qwCustomer: {
    getList: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/get_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    getAllTag: () => doRPC(
      'GET',
      `/api/v1/wx_work_external_user/get_all_tags?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    // 获取添加人员工
    getWorkers: () => doRPC(
      'GET',
      `/api/v1/wx_work_employee/associate_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    // 修改手机号
    updatePhone: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_phone?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    // 修改标签
    updateTags: params => doRPC(
      'POST',
      `/api/v1/wx_work_external_user/edit_tags?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    // 获取所有企业下的所有员工
    getAllEmployes: () => doRPC(
      'GET',
      `/api/v1/wx_work_employee/list_all?accountId=${window.pageProps.uid}`,
      {},
    ),
    // 获取企微客户跟进状态列表
    getFollowStatusList: () => doRPC(
      'GET',
      `/api/v1/wx_work_external_user/followstatus_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    // 获取是否拉取历史企微客户
    getPullQwHistoryCustomerConfig: (params = {}) => doRPC(
      'GET',
      `/api/v1/wx_work_employee/pull_history_user_config?accountId=${window.pageProps.uid}`,
      params,
    ),
    // 设置是否拉取历史企微客户
    setPullQwHistoryCustomerConfig: (params = {}) => {
      const { corpId, wxWorkUserId, config } = params;
      delete params.corpId;
      return doRPC(
        'POST',
        `/api/v1/wx_work_employee/pull_history_user_config?accountId=${window.pageProps.uid}&corpId=${corpId}&wxWorkUserId=${wxWorkUserId}`,
        { config },
      );
    },
  },
  // 企微团队管理
  qwTeam: {
    teamList: params => doRPC(
      'GET',
      `/api/v1/wx_work_group/list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    // 添加团队
    addTeam: params => doRPC(
      'POST',
      `/api/v1/wx_work_group/add?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    editTeam: params => doRPC(
      'POST',
      `/api/v1/wx_work_group/update?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    teamDetail: groupId => doRPC('GET', `/api/v1/wx_work_group/get?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, {
      groupId,
    }),
    deleteDetail: groupId => doRPC('GET', `/api/v1/wx_work_group/delete?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, {
      groupId,
    }),
  },
  // 企微员工管理
  qwEmployee: {
    getList: (params, corpId) => doRPC(
      'POST',
      `/api/v1/wx_work_employee/list?accountId=${window.pageProps.uid}&corpId=${corpId || window.pageProps.corpId}`,
      params || {},
    ),
    getTeamList: params => doRPC(
      'GET',
      `/api/v1/wx_work_group/list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    update: params => doRPC(
      'POST',
      `/api/v1/wx_work_employee/update?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    bind: params => doRPC(
      'POST',
      `/api/v1/wx_work_employee/bind?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    unbind: wxWorkUserId => doRPC(
      'POST',
      `/api/v1/wx_work_employee/unbind?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      { wxWorkUserId },
    ),
  },
  // 企微会话存档接口
  qwArchive: {
    // 获取员工/客服列表
    getEmployeeList: () => doRPC(
      'GET',
      `/api/v1/wx_work/user/get_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      {},
    ),
    // 获取客户列表
    getCustomerList: params => doRPC(
      'GET',
      `/api/v1/wx_work/chat/get_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    getChat: params => doRPC(
      'GET',
      `/api/v1/wx_work/chat/get_messages?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
  },
  // 企微管理端消息管理
  qwMessage: {
    getList: params => doRPC(
      'POST',
      `/api/v1/wx_work_notice/notice_rule_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    associateList: params => doRPC(
      'GET',
      `/api/v1/wx_work_employee/associate_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    noticeRuleAdd: params => doRPC(
      'POST',
      `/api/v1/wx_work_notice/notice_rule_add?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
    noticeRuleUpdate: params => doRPC(
      'POST',
      `/api/v1/wx_work_notice/notice_rule_update?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params || {},
    ),
  },
  // 企微渠道活码
  qwDrainage: {
    create: (params = {}) => doRPC(
      'POST',
      `/api/v1/wx_work_qr_code/create?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    update: (params = {}) => doRPC(
      'POST',
      `/api/v1/wx_work_qr_code/update?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    getDetail: id => doRPC(
      'GET',
      `/api/v1/wx_work_qr_code/detail?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      { id },
    ),
    delete: id => doRPC(
      'POST',
      `/api/v1/wx_work_qr_code/delete?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      { id },
    ),
    getList: (params = {}) => doRPC(
      'GET',
      `/api/v1/wx_work_qr_code/list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
  },
  offlineTask: {
    getList: params => doRPC('POST', `/api/v1/leads/offline_task_list?accountId=${window.pageProps.uid}`, params),
    downloadFailedFile: taskId => doRPC('GET', `/api/v1/leads/download_fail_leads_import?accountId=${window.pageProps.uid}&taskId=${taskId}`, {}),
  },
  dmpReport: {
    getLeadsDmpReport: params => doRPC('POST', `/api/v1/leads_dmp_report_rule/list?accountId=${window.pageProps.uid}`, params),
    setLeadsDmpReport: params => doRPC('POST', `/api/v1/leads_dmp_report_rule/update?accountId=${window.pageProps.uid}`, params),
    getQwDmpReport: params => doRPC(
      'GET',
      `/api/v1/wx_work_report/report_rule_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    setQwDmpReport: params => doRPC(
      'POST',
      `/api/v1/wx_work_report/edit_report_rule?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
    getFollowStatusList: params => doRPC(
      'GET',
      `/api/v1/wx_work_external_user/get_follow_status_list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`,
      params,
    ),
  },
  // 操作日志
  logs: {
    getLogList: (params = {}) => doRPC(
      'GET',
      `/api/v2/operation_log/list?accountId=${window.pageProps.uid}`,
      params,
    ),
    getEmployeeList: queryAccountId => doRPC(
      'GET',
      `/api/v2/operation_log/list_employees?accountId=${window.pageProps.uid}`,
      { queryAccountId },
    ),
  },
  xunda: {
    getPoolData: () => doRPC(
      'GET',
      `/api/v1/leads/list_all_leads_pool_info?accountId=${window.pageProps.uid}`,
      {},
    ),
  },
  leadsReport: {
    getLeadsDistribute: params => doRPC('POST', `/api/v1/employee_statement/leads_allocation?accountId=${window.pageProps.uid}`, params),
    getLeadsFollow: params => doRPC('POST', `/api/v1/employee_statement/leads_follow_up?accountId=${window.pageProps.uid}`, params),
    getEmployeeSettingInfo: () => doRPC('GET', `/api/v1/employee_statement/employee_performance_setting_info?accountId=${window.pageProps.uid}`),
    getEmployeePerformance: params => doRPC('POST', `/api/v1/employee_statement/employee_performance?accountId=${window.pageProps.uid}`, params),
    getLeadsConversion: params => doRPC('POST', `/api/v1/employee_statement/leads_conversion?accountId=${window.pageProps.uid}`, params),
    getConnectionRate: params => doRPC('POST', `/api/v1/employee_statement/leads_connected_rate?accountId=${window.pageProps.uid}`, params),
  },
  DMPUpload: {
    getDmpUploadOverview: params => doRPC('GET', `/api/v1/report_dmp_statement/overview_data?accountId=${window.pageProps.uid}`, params),
    getDmpUploadStatistic: params => doRPC('GET', `/api/v1/report_dmp_statement/optimizing_effect_factor?accountId=${window.pageProps.uid}`, params),
    getDmpUploadDistribution: params => doRPC('GET', `/api/v1/report_dmp_statement/distribution_data?accountId=${window.pageProps.uid}`, params),
    getDmpUploadDetail: params => doRPC('GET', `/api/v1/report_dmp_statement/detail_data?accountId=${window.pageProps.uid}`, params),
    getSubaccountList: () => doRPC('GET', `/api/v1/common/get_subaccount_list?accountId=${window.pageProps.uid}`),
    getActionPathList: () => doRPC('GET', `/api/v1/report_dmp_statement/config?accountId=${window.pageProps.uid}`),
    getActionAPIDistribution: params => doRPC('GET', `/api/v1/report_dmp_action/distribution_data?accountId=${window.pageProps.uid}`, params),
    getActionAPIDetail: params => doRPC('GET', `/api/v1/report_dmp_action/detail_data?accountId=${window.pageProps.uid}`, params),
  },
  wechatWelcome: {
    getWelcomeMsgList: params => doRPC('POST', `/api/v1/wx_work/welcomemsg/list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, params),
    addWelcomeMsg: params => doRPC('POST', `/api/v1/wx_work/welcomemsg/add?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, params),
    updateWelcomeMsg: params => doRPC('POST', `/api/v1/wx_work/welcomemsg/update?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, params),
    getWxworkWelcomeUserList: () => doRPC('GET', `/api/v1/wx_work/welcomemsg_candidate_wxwork_userid/list?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, {}),
    getManufacture: () => doRPC('GET', `/api/v1/manufacture/list?accountId=${window.pageProps.uid}`, {}),
    getMiniprogramPages: params => doRPC('GET', `/api/v1/miniprogram_page/list?accountId=${window.pageProps.uid}`, params),
    deleteWelcomeMsgById: params => doRPC('POST', `/api/v1//wx_work/welcomemsg/delete?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}`, params),
    getWelcomeMsgById: welcomeMsgId => doRPC('GET', `/api/v1/wx_work/welcomemsg/get?accountId=${window.pageProps.uid}&corpId=${window.pageProps.corpId}&welcomeMsgId=${welcomeMsgId}`, {}),
  },
  // 5R相关接口
  traffic: {
    // 资产总量
    // 单天5r接口
    getSingleDayDetail: (date, brand) => doRPC('GET', `/api/v2/5r_single_day/detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}`, {}),
    getSingleDayActionDetail: (date, brand, fiveR) => doRPC('GET', `/api/v2/5r_single_day/action_detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}&fiveR=${fiveR}`, {}),
    // 月度5r接口
    getMonthlyDetail: (date, brand) => doRPC('GET', `/api/v2/5r_monthly/detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}`, {}),
    // 月度资产趋势图数据接口
    getMonthlyTrend: (date, brand, level) => doRPC('GET', `/api/v2/5r_monthly/trend?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}&level=${level}`, {}),
    // campaign流转信息获取接口
    getCampaign: (date, brand) => doRPC('GET', `/api/v2/5r_campaign/get?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}`, {}),
    // 资产日分布下钻接口
    getSingleDayDrillDownDetail: (date, brand, fiveR) => doRPC('GET', `/api/v2/5r_single_day/action_detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}&fiveR=${fiveR}`, {}),
    // 资产月份布下钻接口
    getMonthlyDrillDownDetail: (date, brand, fiveR, drillDownType) => doRPC('GET', `/api/v2/5r_monthly/action_detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}&fiveR=${fiveR}&drillDownType=${drillDownType}`, {}),
    // 流转详情下钻接口
    getCampaignDrillDownData: (date, brand, originFiveR, targetFiveR, adType) => doRPC('GET', `/api/v2/5r_campaign/action_detail?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}&originFiveR=${originFiveR}&targetFiveR=${targetFiveR}&adType=${adType}`, {}),
    // 线索人群行业数据列表
    getLeadsIndustry: date => doRPC('GET', `/api/v2/5r_leads_industry/get?accountId=${window.pageProps.uid}&date=${date}`, {}),
    // 线索人群品牌数据列表
    getLeadsBrand: (date, brand) => doRPC('GET', `/api/v2/5r_leads_brand/get?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}`, {}),
    // 获取月度线索竞争信息
    getLeadsMonthCompetition: (date, brand) => doRPC('GET', `/api/v2/5r_leads_month_competition/get?accountId=${window.pageProps.uid}&date=${date}&brand=${brand}`, {}),
    // 公共接口
    // 品牌枚举列表
    getBrandList: () => doRPC('GET', `/api/v2/5r_brand/list?accountId=${window.pageProps.uid}`, {}),
  },
  // 播放版位
  placenment: {
    getImpsPlacementGroupName: params => doRPC('GET', `/api/v1/leads/placenment_group?accountId=${window.pageProps.uid}`, params),
  },
  // 视频号相关
  videoChannel: {
    // 概览
    getLiveOverview: params => doRPC('POST', '/api/v2/video_channel/live_data/overview', params),
    getLeadsOverview: params => doRPC('POST', '/api/v2/video_channel/leads/overview', params),
    getFansOverview: params => doRPC('POST', '/api/v2/video_channel/fans/overview', params),
    // filter常量数据
    getDealerList: params => doRPC('GET', '/api/v2/video_channel/dealer/list', params),
    getVideoChannelList: params => doRPC('POST', '/api/v2/video_channel/app/list', params),
    getBrandList: params => doRPC('GET', '/api/v2/video_channel/brand/list', params),
    // 权限
    getUserList: params => doRPC('GET', '/api/v2/video_channel/user/list', params),
    deteleUser: params => doRPC('GET', '/api/v2/video_channel/user/delete', params),
    updateUser: params => doRPC('Post', '/api/v2/video_channel/user/update', params),
    addUser: params => doRPC('Post', '/api/v2/video_channel/user/add', params),
    getWxidList: params => doRPC('GET', '/api/v2/video_channel/user/wxid_list', params),
    // 排行榜
    getLivingRankingList: params => doRPC('Post', '/api/v2/video_channel/living/leaderboard/list', params),
    getLeadsRankingList: params => doRPC('Post', '/api/v2/video_channel/leads/leaderboard/list', params),
    getFansRankingList: params => doRPC('Post', '/api/v2/video_channel/fans/leaderboard/list', params),
    // 微信授权图片
    getWechatAuthImg: params => doRPC('GET', '/api/v2/wechat_channel/authorize', params),
  },
};
